import React, {useState} from 'react';
import {connect} from 'react-redux';
import * as actions from './orderHistoryActions';
import {editPreviousProductConfiguration} from '../ProductCustomizer/productCustomizerActions';
import OrderHistory from './OrderHistory';

const OrderHistoryContainer = ({
  orderHistory,
  setOrderHistoryModalIsVisible,
  addProductsToCart,
  isCategory,
  editPreviousProductConfiguration,
  isFromPreviousOrder,
  orderHistoryModalIsVisible,
  setSingleOrderHistory,
  products,
}) => {
  //filter order history to remove gift card buying/reloading transactions
  if (orderHistory && orderHistory.length) {
    orderHistory = orderHistory.filter(order => order.config_products.length);
  }
  if (orderHistory && orderHistory.length && products.length) {
    return (
      <OrderHistory
        setOrderHistoryModalIsVisible={setOrderHistoryModalIsVisible}
        setSingleOrderHistory={setSingleOrderHistory}
        orderHistoryModalIsVisible={orderHistoryModalIsVisible}
        orderHistory={orderHistory.slice(0, 10)}
        products={products}
        addProductsToCart={addProductsToCart}
        isCategory={isCategory}
        editPreviousProductConfiguration={editPreviousProductConfiguration}
        isFromPreviousOrder={isFromPreviousOrder}
      />
    );
  } else return null;
};

const mapStateToProps = state => ({
  orderHistory: mapOrderHistory(
    state.orderHistoryReducer.orderHistory,
    state.menuReducer.products
  ),
  products: state.menuReducer.products,
  isFromPreviousOrder: state.productCustomizerReducer.isFromPreviousOrder,
  orderHistoryModalIsVisible:
    state.orderHistoryReducer.orderHistoryModalIsVisible,
});

const mapOrderHistory = (orderHistory, products) => {
  return orderHistory.map(order => {
    return {
      ...order,
      config_products: order.config_products.map(orderProduct => {
        let foundProduct = products.find(
          product => product.product_id === orderProduct.product_id
        );
        if (foundProduct) {
          let foundVariant = foundProduct.prices.find(
            price => price.variant_id === orderProduct.variant_id
          );
          if (foundVariant) {
            return {
              ...orderProduct,
              price: foundVariant.price,
              prices: foundProduct.prices,
            };
          } else {
            return orderProduct;
          }
        } else {
          return orderProduct;
        }
      }),
    };
  });

  return orderHistory;
};

export default connect(mapStateToProps, {
  ...actions,
  editPreviousProductConfiguration,
})(OrderHistoryContainer);
