export const displayOptionsItems = options =>
  options.map(option => {
    if (option.items && option.items.length) {
      return (
        option.name +
        ' - ' +
        option.items
          .map(item => {
            if (item.sub_items && item.sub_items.length) {
              return (
                item.name +
                ' - ' +
                item.sub_items
                  .map(subItem => {
                    return subItem.name;
                  })
                  .join(', ')
              );
            }
            return item.name;
          })
          .join(', ') +
        ';\n'
      );
    } else if (option.sub_options && option.sub_options.length) {
      return (
        option.name +
        ' - ' +
        option.sub_options
          .map(subOption => {
            if (subOption.items && subOption.items.length) {
              return (
                subOption.name +
                ' - ' +
                subOption.items
                  .map(item => {
                    if (item.sub_items && item.sub_items.length) {
                      return (
                        item.name +
                        ' - ' +
                        item.sub_items
                          .map(subItem => {
                            return subItem.name;
                          })
                          .join(', ')
                      );
                    }
                    return item.name;
                  })
                  .join(', ')
              );
            }
          })
          .join(', ')
      );
    }
  });

export const getItemCount = options =>
  options
    .map(option =>
      option.items
        ? option.items
            .map(item => (item.sub_items ? item.sub_items.length : 1))
            .reduce((a, b) => a + b, 0)
        : option.sub_options &&
          option.sub_options
            .map(subOption =>
              subOption.items
                .map(item => (item.sub_items ? item.sub_items.length : 1))
                .reduce((a, b) => a + b, 0)
            )
            .reduce((a, b) => a + b, 0)
    )
    .reduce((a, b) => a + b, 0);
