import React from 'react';
import {connect} from 'react-redux';
import styled from 'styled-components';
import Dialog from '@material-ui/core/Dialog';
import * as actions from '../orderHistoryActions';
import {withStyles} from '@material-ui/core/styles';
import {Headline, Caption1, Title1} from '../../../components/Typography';
import {PrimaryButton, AddButton} from '../../../components/Buttons';
import {getItemCount} from '../utils/displayOptionsItems';
import appConfig from '../../../../app-config';
import moment from 'moment';

const styles = () => ({
  paper: {
    margin: 0,
    maxWidth: '600px',
  },
});

const Wrapper = styled.div`
  padding: 12px;
  // width: 600px;

  @media (min-width: 786px) {
    padding: 32px;
    width: 600px;
  }
`;

const TitleWrapper = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
`;

const Title = styled(Title1)`
  color: ${props => props.theme.black};
  font-weight: 600;
`;

const DateAndAddAll = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background: ${props => props.theme.orderAgainTitleBackgroundColor};
  color: ${props => props.theme.orderAgainTitleFontColor};
  border-radius: 8px;
  height: 51px;
  padding: 0 10px;
`;

const Cancel = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: white;
  background-color: ${props => props.theme.darkGray};
  right: 8px;
  top: 8px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background: ${props => props.theme.closeButtonHoverColor};
    transition: all 0.25s ease;
  }
`;

const AddAllButton = styled(PrimaryButton)`
  border: 1px solid ${props => props.theme.orderAgainButtonBorderColor};
  height: 36px;
  font-size: 14px;
  box-shadow: none;
  border-radius: ${props => props.theme.addAllOrdersButtonRadius};
  background: ${props => props.theme.orderAgainTitleButtonBackgroundColor};
  color: ${props => props.theme.orderAgainTitleButtonFontColor};
  font-weight: 600;
  text-transform: ${props =>
    props.theme.addAllButtonTextTransform
      ? props.theme.addAllButtonTextTransform
      : 'uppercase'};

  &:hover {
    background: ${props =>
      props.theme.orderAgainTitleButtonBackgroundColorHover};
    border: 0;
    color: ${props =>
      props.theme.orderAgainTitleButtonTextColorHover} !important;
    transition: all 0.25s ease;
  }
`;
const OrderWrapper = styled.div`
  border: 1px solid ${props => props.theme.orderAgainBorderColor};
  border-radius: 12px;
`;

const Products = styled.div`
  margin: 10px 0;
`;

const ProductWrapper = styled.div`
  display: grid;
  grid-template-columns: ${props => (props.isNoImage ? null : '70px auto')};
  grid-gap: 5px;
  padding: 10px 15px;
  align-items: center;
  cursor: ${props => (props.isAvailable ? 'pointer' : 'default')};

  &:hover {
    background-color: #faf9f9;
    transition: all 0.25s ease;
  }

  @media (max-width: 768px) {
    grid-template-columns: 56px auto;
    grid-gap: 5px;
  }
`;

const ProductImage = styled.img`
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 50%;
  color: ${props => (props.isAvailable ? 'inherit' : props.theme.gray)};
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
  @media (max-width: 768px) {
    height: 56px;
    width: 56px;
  }
`;

const NonProductImage = styled.div`
  display: grid;
  grid-gap: 19px;

  @media (max-width: 768px) {
    grid-gap: 16px;
  }
`;

const ProductInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 72px;
  grid-gap: 5px;
  align-items: center;

  @media (max-width: 768px) {
    grid-gap: 5px;
  }
`;

const ProductInfo = styled.div`
  display: grid;
  grid-gap: 6px;

  @media (max-width: 768px) {
    grid-gap: 2px;
  }
`;

const ProductName = styled(Headline)`
  color: ${props =>
    props.isAvailable
      ? props.theme.black
      : props.theme.unavailableProductColor};
  text-transform: capitalize;
`;

const ProductDescription = styled(Caption1)`
  color: ${props =>
    props.isAvailable
      ? props.theme.darkGray
      : props.theme.unavailableProductColor};
  @media (max-width: 768px) {
    // display: none;
  }
`;

const PriceAndAdd = styled.div`
  display: grid;
  grid-gap: 6px;
`;

const CardTitle = styled(Headline)`
  color: ${props => props.theme.orderAgainTitleFontColor};
`;

const orderHistoryModal = ({
  orderHistoryModalIsVisible,
  setOrderHistoryModalIsVisible,
  classes,
  selectedOrderHistory,
  addProductsToCart,
  editPreviousProductConfiguration,
  products,
}) => {
  if (selectedOrderHistory) {
    const {
      order: singleOrderHistory,
      sortedConfigProducts,
      orderedAvailableProducts,
    } = selectedOrderHistory;
    return (
      <Dialog
        open={orderHistoryModalIsVisible}
        onBackdropClick={() => setOrderHistoryModalIsVisible(false)}
        fullWidth={typeof window !== 'undefined' && window.innerWidth < 786}
        PaperProps={{classes: {root: classes.paper}}}
        aria-labelledby="order-history-modal"
        scroll="body"
      >
        <Wrapper>
          <TitleWrapper>
            <Title id="OrderHistory-PastOrders">Past Orders</Title>
            <Cancel
              id="OrderHistory-HistoryToggle"
              onClick={() => setOrderHistoryModalIsVisible(false)}
            >
              <i
                style={{fontSize: '20px', fontWeight: '400'}}
                className="fal fa-times"
              />
            </Cancel>
          </TitleWrapper>
          <OrderWrapper style={{margin: '10px'}}>
            <DateAndAddAll>
              <CardTitle>
                {moment(singleOrderHistory.order_date).format('MMM DD, h:mm A')}
              </CardTitle>
              {orderedAvailableProducts.length > 1 && (
                <AddAllButton
                  id="OrderHistory-AddAllButton"
                  small
                  onClick={() => addProductsToCart(orderedAvailableProducts)}
                >
                  add {orderedAvailableProducts.length} items
                </AddAllButton>
              )}
            </DateAndAddAll>
            <Products>
              {sortedConfigProducts.map((product, index) => {
                const foundProduct = products.find(
                  menuProduct => menuProduct.product_id === product.product_id
                );
                return (
                  <ProductWrapper
                    key={index}
                    // isCategory={isCategory}
                    isNoImage={appConfig.isNoImage}
                    isAvailable={
                      foundProduct && foundProduct.is_product_available
                    }
                    onClick={() => {
                      foundProduct &&
                        foundProduct.is_product_available &&
                        editPreviousProductConfiguration(product);
                    }}
                  >
                    {!appConfig.isNoImage && (
                      <ProductImage
                        isAvailable={
                          foundProduct && foundProduct.is_product_available
                        }
                        src={product.image_url}
                      />
                    )}
                    <NonProductImage>
                      <ProductInfoWrapper>
                        <ProductInfo>
                          <ProductName
                            id="OrderHistory-ProductName"
                            isAvailable={
                              foundProduct && foundProduct.is_product_available
                            }
                          >
                            {product.name}
                            {product.quantity > 1 && ` x${product.quantity}`}
                          </ProductName>
                          {product.options && (
                            <ProductDescription
                              id="OrderHistory-ProductDescription"
                              isAvailable={
                                foundProduct &&
                                foundProduct.is_product_available
                              }
                            >
                              <p style={{fontSize: '14px'}}>
                                {' '}
                                {getItemCount(product.options)} customization
                              </p>
                            </ProductDescription>
                          )}
                        </ProductInfo>
                        <PriceAndAdd>
                          <div />
                          {foundProduct && foundProduct.is_product_available ? (
                            <AddButton
                              id="OrderHistory-AddButton"
                              onClick={e => {
                                addProductsToCart([product]);
                                e.stopPropagation();
                              }}
                            />
                          ) : (
                            <p
                              style={{
                                color:
                                  appConfig.styleConfig.unavailableProductColor,
                              }}
                            >
                              Sold Out
                            </p>
                          )}
                        </PriceAndAdd>
                      </ProductInfoWrapper>
                    </NonProductImage>
                  </ProductWrapper>
                );
              })}
            </Products>
          </OrderWrapper>
        </Wrapper>
      </Dialog>
    );
  }
  return null;
};

const mapStateToProps = state => ({
  orderHistoryModalIsVisible:
    state.orderHistoryReducer.orderHistoryModalIsVisible,
});

export default withStyles(styles)(
  connect(mapStateToProps, actions)(orderHistoryModal)
);
