import React, {useState} from 'react';
import styled from 'styled-components';
import moment from 'moment';
import {Headline, Caption1, Title1, Title3} from '../../components/Typography';
import {PrimaryButton, AddButton} from '../../components/Buttons';
import {getItemCount} from './utils/displayOptionsItems';
import Slider from 'react-slick';
import OrderHistoryModal from './components/OrderHistoryModal';
import appConfig from '../../../app-config';

const Wrapper = styled.div``;

const TitleWrapper = styled.div`
  width: max-content;
  font-weight: 600;
  // margin-bottom: 20px;

  :after {
    content: '';
    display: block;
    width: calc(100% + 2px);
    padding-top: 2px;
    border-bottom: ${props => props.categoriesBarSelectedBorderColorWidth} solid
      ${props => props.theme.categoriesBarSelectedBorderColor};
  }
`;

const DateAndAddAll = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  background: ${props => props.theme.orderAgainTitleBackgroundColor};
  border-radius: 8px;
  height: 51px;
  padding: 0 20px;

  @media (max-width: 768px) {
    grid-template-columns: auto 100px;
  }
`;

const CardTitle = styled(Headline)`
  color: ${props => props.theme.orderAgainTitleFontColor};
`;

const AddAllButton = styled(PrimaryButton)`
  border: 2px solid ${props => props.theme.orderAgainButtonBorderColor};
  height: 36px;
  font-size: 14px;
  box-shadow: none;
  border-radius: ${props => props.theme.addAllOrdersButtonRadius};
  background: ${props => props.theme.orderAgainTitleButtonBackgroundColor};
  color: ${props => props.theme.orderAgainTitleButtonFontColor};
  font-weight: 600;

  &:hover {
    background: ${props =>
      props.theme.orderAgainTitleButtonBackgroundColorHover};
    border: 0;
    color: ${props => props.theme.orderAgainTitleButtonTextColorHover};
    transition: all 0.25s ease;
  }
`;

const ImageStackWrapper = styled.div`
  display: flex;
  flex-flow: row-reverse;
`;

const ImageStack = styled.img`
  vertical-align: middle;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: flex-end;
  margin-left: ${props => (props.isFirstImage ? '0' : '-10px')};
  border: 1px solid white;
  color: ${props => (props.isAvailable ? 'inherit' : props.theme.gray)};
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
  ${props => (props.isAvailable ? '' : 'z-index: -1')};
`;

const MoreItems = styled.p`
  font-family: ${props => props.theme.primaryFontFontFamily};
  align-self: center;
  font-size: 14px;
  font-weight: 600;
  width: 35%;
`;

const OrderWrapper = styled.div`
  margin-top: ${props => (props.isCategory ? '0' : '48px')};
  border: 1px solid ${props => props.theme.orderAgainBorderColor};
  border-radius: 12px;
`;

const ImageStackAndViewAll = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  margin: 10px 20px 20px 20px;
`;

const ProductWrapper = styled.div`
  display: grid;
  width: inherit;
  grid-template-columns: ${props => (props.isNoImage ? null : '70px auto')};
  grid-gap: 5px;
  padding: 20px 15px 10px 15px;
  align-items: center;
  cursor: ${props => (props.isAvailable ? 'pointer' : 'default')};

  &:hover {
    background-color: #faf9f9;
    transition: all 0.25s ease;
  }

  @media (max-width: 768px) {
    grid-template-columns: 56px auto;
    grid-gap: 5px;
  }
`;

const ProductImage = styled.img`
  height: 70px;
  width: 70px;
  object-fit: cover;
  border-radius: 50%;
  border: ${props => props.theme.orderHistoryProductImageBorder};
  color: ${props => (props.isAvailable ? 'inherit' : props.theme.gray)};
  filter: ${props => (props.isAvailable ? null : 'grayscale(0.9)')};
  @media (max-width: 768px) {
    height: 56px;
    height: 56px;
  }
`;

const ViewAll = styled.div`
  font-family: ${props => props.theme.primaryFontFontFamily};
  align-self: center;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
`;

const NonProductImage = styled.div`
  display: grid;
  grid-gap: 19px;

  @media (max-width: 768px) {
    grid-gap: 16px;
  }
`;

const ProductInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 72px;
  grid-gap: 5px;
  align-items: center;

  @media (max-width: 768px) {
    grid-gap: 5px;
  }
`;

const ProductInfo = styled.div`
  display: grid;
  grid-gap: 6px;

  @media (max-width: 768px) {
    grid-gap: 2px;
  }
`;

const ProductName = styled(Headline)`
  color: ${props =>
    props.isAvailable
      ? props.theme.black
      : props.theme.unavailableProductColor};
  text-transform: capitalize;
`;

const ProductDescription = styled(Caption1)`
  color: ${props =>
    props.isAvailable
      ? props.theme.darkGray
      : props.theme.unavailableProductColor};
  @media (max-width: 768px) {
    // display: none;
  }
`;

const PriceAndAdd = styled.div`
  display: grid;
  grid-gap: 6px;
`;

const SliderInner = styled.div`
  outline: none;
  width: auto;
`;

const NoOrdersFound = styled(Title1)`
  text-align: center;
  color: ${props => props.theme.gray};
  margin-top: 64px;
`;
const NextArrow = styled.div`
  @media (max-width: 1100px) {
    right: 10px !important;
  }
  @media (max-width: 786px) {
    display: none !important;
  }
  @media (orientation: landscape) and (max-width: 1100px) {
    right: -20px !important;
  }
`;

const PrevArrow = styled.div`
  @media (max-width: 786px) {
    display: none !important;
  }
`;

function OrderHistoryNextArrow(props) {
  const {className, style, onClick} = props;
  return (
    <NextArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#fff',
        borderRadius: '50%',
        border: 'none',
        height: '40px',
        width: '40px',
        boxShadow: ' 0 2px 5px 0 rgba(59, 76, 112, 0.24)',
        // right: '-10px',
        opacity: 1,
        fontSize: '16px',
        lineHeight: '40px',
        textAlign: 'center',
      }}
      onClick={onClick}
    />
  );
}

function OrderHistoryPrevArrow(props) {
  const {className, style, onClick} = props;
  return (
    <PrevArrow
      className={className}
      style={{
        ...style,
        display: 'block',
        background: '#fff',
        borderRadius: '50%',
        border: 'none',
        height: '40px',
        width: '40px',
        boxShadow: ' 0 2px 5px 0 rgba(59, 76, 112, 0.24)',
        // left: '-20px',
        opacity: 1,
        lineHeight: '40px',
        textAlign: 'center',
        zIndex: '1',
      }}
      onClick={onClick}
    />
  );
}

const OrderHistory = ({
  orderHistory,
  addProductsToCart,
  isCategory,
  editPreviousProductConfiguration,
  setOrderHistoryModalIsVisible,
  orderHistoryModalIsVisible,
  setSingleOrderHistory,
  products,
}) => {
  const [selectedOrderHistory, setSelectedOrderHistory] = useState(null);

  const slideSettings = {
    dots: false,
    infinite: false,
    speed: 600,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 2,
    initialSlide: 0,
    swipeToSlide: true,
    nextArrow: <OrderHistoryNextArrow />,
    prevArrow: <OrderHistoryPrevArrow />,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          nextArrow: <OrderHistoryNextArrow />,
          prevArrow: <OrderHistoryPrevArrow />,
        },
      },
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 600,
          arrows: true,
          nextArrow: <OrderHistoryNextArrow />,
          prevArrow: <OrderHistoryPrevArrow />,
        },
      },
      {
        breakpoint: 786,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 600,
          arrows: true,
          nextArrow: <OrderHistoryNextArrow />,
          prevArrow: <OrderHistoryPrevArrow />,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: false,
          infinite: false,
          speed: 600,
          arrows: false,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <Wrapper isCategory={isCategory} className="orderAgainSlider">
      <TitleWrapper>
        <Title1 style={{ fontWeight: "600" }}>Order Again</Title1>
      </TitleWrapper>
      <Slider {...slideSettings}>
        {!orderHistory.length ? (
          <NoOrdersFound>No Orders Found</NoOrdersFound>
        ) : (
          orderHistory
            .filter(order => order.config_products.length)
            .map((order, index) => {
              const orderProducts = order.config_products.map(product => {
                const foundProduct = products.find(
                  menuProduct => menuProduct.product_id === product.product_id
                );
                return {
                  ...product,
                  menuProduct: foundProduct,
                  is_product_available:
                    foundProduct && foundProduct.is_product_available,
                };
              });
              const sortedConfigProducts = orderProducts.sort((p2, p1) => {
                return !p1.is_product_available && p2.is_product_available
                  ? -1
                  : 0;
              });
              const orderedAvailableProducts = sortedConfigProducts.filter(
                product => product.is_product_available
              );
              const firstProduct = sortedConfigProducts[0];
              const foundProduct = firstProduct.menuProduct;
              const remainingProducts = sortedConfigProducts.slice(
                1,
                sortedConfigProducts.length
              );
              return (
                <SliderInner key={index}>
                  <OrderWrapper
                    style={{
                      marginBottom: '0px',
                      marginRight: '10px',
                      marginLeft: '10px',
                    }}
                  >
                    <DateAndAddAll>
                      <CardTitle>
                        {moment(order.order_date).format('MMM DD, h:mm A')}
                      </CardTitle>
                      {orderedAvailableProducts.length > 1 && (
                        <AddAllButton
                          small
                          onClick={() =>
                            addProductsToCart(orderedAvailableProducts)
                          }
                        >
                          ADD {orderedAvailableProducts.length} ITEMS
                        </AddAllButton>
                      )}
                    </DateAndAddAll>
                    <ProductWrapper
                      isNoImage={appConfig.isNoImage}
                      isCategory={isCategory}
                      isAvailable={
                        foundProduct && foundProduct.is_product_available
                      }
                      onClick={() => {
                        foundProduct &&
                          foundProduct.is_product_available &&
                          editPreviousProductConfiguration(firstProduct);
                      }}
                    >
                      {!appConfig.isNoImage && (
                        <ProductImage
                          isAvailable={
                            foundProduct && foundProduct.is_product_available
                          }
                          src={firstProduct.image_url}
                        />
                      )}
                      <NonProductImage>
                        <ProductInfoWrapper>
                          <ProductInfo>
                            <ProductName
                              isAvailable={
                                foundProduct &&
                                foundProduct.is_product_available
                              }
                            >
                              {firstProduct.name.length > 10
                                ? `${firstProduct.name
                                    .toLowerCase()
                                    .substr(0, 10)}...`
                                : firstProduct.name.toLowerCase()}
                              {firstProduct.quantity > 1 &&
                                ` x${firstProduct.quantity}`}
                            </ProductName>
                            {firstProduct.options && (
                              <ProductDescription
                                isAvailable={
                                  foundProduct &&
                                  foundProduct.is_product_available
                                }
                              >
                                <p style={{fontSize: '14px'}}>
                                  {' '}
                                  {getItemCount(firstProduct.options)}{' '}
                                  customization
                                </p>
                              </ProductDescription>
                            )}
                          </ProductInfo>
                          <PriceAndAdd>
                            <div />
                            {foundProduct &&
                            foundProduct.is_product_available ? (
                              <AddButton
                                onClick={e => {
                                  addProductsToCart([firstProduct]);
                                  e.stopPropagation();
                                }}
                              />
                            ) : (
                              <p
                                style={{
                                  color:
                                    appConfig.styleConfig
                                      .unavailableProductColor,
                                }}
                              >
                                Sold Out
                              </p>
                            )}
                          </PriceAndAdd>
                        </ProductInfoWrapper>
                      </NonProductImage>
                    </ProductWrapper>
                    {remainingProducts.length > 0 && (
                      <ImageStackAndViewAll>
                        <div style={{display: 'flex'}}>
                          {!appConfig.isNoImage && (
                            <ImageStackWrapper>
                              {remainingProducts
                                .slice(0, 3)
                                .reverse()
                                .map((product, index) => {
                                  const foundProduct = product.menuProduct;
                                  return (
                                    <ImageStack
                                      key={index}
                                      isAvailable={
                                        foundProduct &&
                                        foundProduct.is_product_available
                                      }
                                      src={product.image_url}
                                      isFirstImage={
                                        index === remainingProducts.length - 1
                                          ? true
                                          : false
                                      }
                                    />
                                  );
                                })}
                            </ImageStackWrapper>
                          )}
                          {remainingProducts.length > 3 && (
                            <MoreItems>
                              + {remainingProducts.length - 3} more items
                            </MoreItems>
                          )}
                        </div>
                        <ViewAll
                          onClick={() => {
                            setOrderHistoryModalIsVisible(true);
                            setSelectedOrderHistory({
                              order,
                              sortedConfigProducts,
                              orderedAvailableProducts,
                            });
                          }}
                        >
                          View all{' '}
                          <i
                            style={{fontSize: '17px', fontWeight: '600'}}
                            className={`fal fa-chevron-right`}
                          />
                        </ViewAll>
                      </ImageStackAndViewAll>
                    )}
                  </OrderWrapper>
                </SliderInner>
              );
            })
        )}
      </Slider>
      <OrderHistoryModal
        open={orderHistoryModalIsVisible && selectedOrderHistory}
        addProductsToCart={addProductsToCart}
        editPreviousProductConfiguration={editPreviousProductConfiguration}
        products={products}
        selectedOrderHistory={selectedOrderHistory}
      />
    </Wrapper>
  );
};

export default OrderHistory;
